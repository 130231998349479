import React from 'react'
import styles from './KeepReading.module.scss'
import { PostCard } from '../home/FeaturedPosts'

const KeepReading = ({ posts }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h5>Keep reading</h5>
        <div className={styles.grid}>
          {posts.slice(0, 3).map(post => (
            <PostCard
              id={post.id}
              title={post.frontmatter.title}
              slug={post.fields.slug}
              image={post.frontmatter.featuredImage}
              excerpt={post.frontmatter.meta.description}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default KeepReading
